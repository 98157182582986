import { environment } from "src/environments/environment";
import { IMenuOption } from "../../models/imenu-option.interface";
import { HttpClient, HttpParams } from "@angular/common/http";
import { map } from "rxjs/operators";
import { Injectable } from '@angular/core';
@Injectable({
    providedIn: "root",
  })
export class MenuRepositoryService {
  constructor(private http: HttpClient) {}
  
  getMenu(username: string) {
    let url = environment.urlApi + "Menu/GetMenuOptionByUserAndOrigin";
    return this.http
      .get<IMenuOption[]>(url, {
        params: new HttpParams()
          .append("userAccount", username)
          .append("origin", "P"),
      })
      .pipe(
        map((data: any) => {
          if (data.isValid) return data.data;
          return [];
        })
      );
  }
}
