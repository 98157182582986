import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { Router } from '@angular/router';
import { AlertController, LoadingController } from '@ionic/angular';
import { BehaviorSubject, Subject } from 'rxjs';
import { IAuthResponse } from 'src/app/models/iauth-response.interface';
import { LoginInformation } from 'src/app/models/login-information.model';
import { AuthRepositoryService } from './auth-repository.service';
import { IUser } from 'src/app/models/iuser.interface';
import { UserRepositoryService } from '../user/user-repository.service';
import { MasterTableRepositoryService } from '../master-table/master-table-repository.service';
import { IMenuOption } from 'src/app/models/imenu-option.interface';
import { MenuRepositoryService } from '../menu/menu-repository.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  authResponseSubject = new BehaviorSubject<IAuthResponse>(null);
  private _loginInfo: LoginInformation = new LoginInformation();
  private timer: any;
  _tokenFCM: string;
  _userIdOneSignal: string;
  public _tiposDocumentos: any[] = [];

  constructor(
    private storage: Storage,
    private router: Router,
    private alertCtrl: AlertController,
    private loadingCtrl: LoadingController,
    private authRepositoryService: AuthRepositoryService,
    private userRepositoryService: UserRepositoryService,
    private masterTableRepositoryService: MasterTableRepositoryService,
    private menuRepositoryService: MenuRepositoryService,
    private http: HttpClient
  ) {}

  async login(username: string, password: string) {
    let loading = await this.loadingCtrl.create({
      keyboardClose: true,
      message: 'Iniciando sesión...'
    });
    await loading.present();
    this.authRepositoryService.login(username, password).subscribe(
      (authResponse: IAuthResponse) => {
        this._loginInfo.expires_in = authResponse.expires_in;
        this._loginInfo.access_token = authResponse.access_token;
        this._loginInfo.token_type = authResponse.token_type;
        this.authResponseSubject.next(this._loginInfo);
        loading.message = 'Cargando la información de usuario...';

        this.userRepositoryService
          .getUser(username)
          .subscribe((userData: IUser) => {
            console.log(userData);
            this._loginInfo.idUser = userData.idUser;
            this._loginInfo.userAccount = userData.userAccount;
            this._loginInfo.firstName = userData.firstName;
            this._loginInfo.lastName = userData.lastName;
            this._loginInfo.email = userData.email;
            this._loginInfo.password = userData.password;
            this._loginInfo.phone = userData.phone;
            this._loginInfo.mobile = userData.mobile;
            this._loginInfo.birthDate = userData.birthDate;
            this._loginInfo.originApp = userData.originApp;
            this._loginInfo.idUserDocumentType = userData.idUserDocumentType;
            this._loginInfo.documentNumber = userData.documentNumber;
            this._loginInfo.rolType = userData.rolType;
            this._loginInfo.idBusinessPartner = userData.idBusinessPartner;
            this._loginInfo.userRoleTypeCodes = userData.userRoleTypeCodes;

            loading.message = 'Cargando permisos...';
            this.menuRepositoryService
              .getMenu(this._loginInfo.userAccount)
              .subscribe((menuOptions: IMenuOption[]) => {
                this._loginInfo.permissions = menuOptions;
                console.log(this._loginInfo);
                loading.dismiss();
                this.guardarStorage();
                this.autoLogout(this._loginInfo.expires_in);

                this.registerPushToken().then((token) => {
                  if (this._loginInfo.permissions.length > 0) {
                    this.router.navigateByUrl(
                      this._loginInfo.permissions[0].menuOptionUrl
                    );
                  } else {
                    this.router.navigateByUrl('/');
                  }
                });
                //this.router.config[0].redirectTo = "user-account"
                // this.router.navigateByUrl('/');
              });
          });
      },
      async (data) => {
        console.log(data);
        await loading.dismiss();
        const alert = await this.alertCtrl.create({
          header: 'Error',
          buttons: ['OK'],
          message: 'E-mail o contraseña incorrectos.'
        });
        await alert.present();
      }
    );
  }

  logout() {
    this.borrarStorage()
      .then(() => {
        this.authResponseSubject.next(null);
        this.router.navigateByUrl('/auth');
      })
      .catch(() => {
        //
      });
  }

  autoLogin() {
    // this.cargarStorage2().then(() => {
    //   if (this._loginInfo) {
    //     this.autoLogout(this._loginInfo.expires_in);
    //   }
    // });
  }

  private autoLogout(expiratioduration: number) {
    this.timer = setTimeout(() => this.logout(), expiratioduration);
  }

  approve(userAccount: string) {
    return this.authRepositoryService.approve(userAccount);
  }

  resetPassword(email: string) {
    let promesa = new Promise((resolve, reject) => {
      this.authRepositoryService.resetPassword(email).subscribe(
        (res: any) => {
          resolve();
        },
        async (error) => {
          const alert = await this.alertCtrl.create({
            header: 'Error',
            subHeader: 'La cuenta de correo no se encuentra registrada',
            buttons: ['OK']
          });

          await alert.present();

          reject('La cuenta de correo no se encuentra registrada');
        }
      );
    });

    return promesa;
  }

  getTiposDocumentos() {
    let promesa = new Promise((resolve, reject) => {
      this.masterTableRepositoryService.getMasterTable('UDT').subscribe(
        async (res: any) => {
          // console.log(res);
          const resultado = res;

          // if (!resultado.data) {
          if (!resultado) {
            this._tiposDocumentos = [];
            resolve();
          } else {
            // si se obtienen los datos del usuario
            this._tiposDocumentos = [];
            // this._tiposDocumentos.push(...resultado.data);
            this._tiposDocumentos.push(...resultado);
            resolve();
          }
        },
        async (error) => {
          // handle error
          console.log('Error en servicio', error);

          const alert = await this.alertCtrl.create({
            header: 'Error',
            subHeader: 'No hay conexion a internet', // data_resp["api_message"],
            buttons: ['OK']
          });

          await alert.present();

          resolve();
        }
      );
    });

    return promesa;
  }

  getMyUserData() {
    const promesa = new Promise((resolve, reject) => {
      this.userRepositoryService.getUser(this.getUser().userAccount).subscribe(
        async (userData: IUser) => {
          // console.log(res);
          console.log(userData);
          // si se obtienen los datos del usuario
          this._loginInfo.idUser = userData.idUser;
          this._loginInfo.userAccount = userData.userAccount;
          this._loginInfo.firstName = userData.firstName;
          this._loginInfo.lastName = userData.lastName;
          this._loginInfo.email = userData.email;
          this._loginInfo.password = userData.password;
          this._loginInfo.phone = userData.phone;
          this._loginInfo.mobile = userData.mobile;
          this._loginInfo.birthDate = userData.birthDate;
          this._loginInfo.originApp = userData.originApp;
          this._loginInfo.idUserDocumentType = userData.idUserDocumentType;
          this._loginInfo.documentNumber = userData.documentNumber;
          this._loginInfo.rolType = userData.rolType;
          this._loginInfo.idBusinessPartner = userData.idBusinessPartner;
          this._loginInfo.userRoleTypeCodes = userData.userRoleTypeCodes;
          this.guardarStorage();

          resolve();
        },
        async (error) => {
          // handle error
          console.log('Error en servicio', error);

          const alert = await this.alertCtrl.create({
            header: 'Error',
            subHeader: 'No se pudo cargar la información', // data_resp["api_message"],
            buttons: ['OK']
          });

          await alert.present();

          resolve();
        }
      );
    });

    return promesa;
  }

  registerPushToken() {
    let promesa = new Promise((resolve, reject) => {
      let url =
        environment.urlApi + 'UserDeviceApp/CreateOrUpdateUserDeviceApp';

      const httpOptions = {
        headers: new HttpHeaders({
          Accept: 'application/json',
          Authorization: 'Bearer ' + this.getUser().access_token
        })
      };

      const datos = {
        idUserDeviceApp: 0,
        idUser: this.getUser().idUser,
        idDevice: this._userIdOneSignal,
        sessionToken: this._tokenFCM,
        application: 'PRT'
      };

      this.http.post(url, datos, httpOptions).subscribe(
        async (res: any) => {
          const resultado = res;

          if (!resultado.isValid) {
            const alert = await this.alertCtrl.create({
              header: 'Error',
              subHeader: resultado.message,
              buttons: ['OK']
            });
            await alert.present();
            resolve();
          } else {
            // si se actualizo satisfactoriamente
            resolve();
          }
        },
        async (error: any) => {
          const alert = await this.alertCtrl.create({
            header: 'Error',
            subHeader: 'No se pudo activar el envío de alertas.', // JSON.stringify(error),
            buttons: ['OK']
          });
          await alert.present();
          console.log(error);
          // console.log(error.error.error);

          resolve();
        }
      );
    });

    return promesa;
  }

  getUser() {
    return this._loginInfo;
  }

  guardarStorage() {
    this.storage.set('loginInfo', JSON.stringify(this._loginInfo));
  }
  borrarStorage() {
    return this.storage.remove('loginInfo');
  }

  cargarStorage2() {
    let promesa = new Promise((resolve, reject) => {
      this.storage.get('loginInfo').then((val) => {
        if (val) {
          const jsonLoginInfo = JSON.parse(val);
          this._loginInfo = new LoginInformation();

          this._loginInfo.idUser = jsonLoginInfo.idUser;
          this._loginInfo.userAccount = jsonLoginInfo.userAccount;
          this._loginInfo.firstName = jsonLoginInfo.firstName;
          this._loginInfo.lastName = jsonLoginInfo.lastName;
          this._loginInfo.email = jsonLoginInfo.email;
          this._loginInfo.password = jsonLoginInfo.password;
          this._loginInfo.phone = jsonLoginInfo.phone;
          this._loginInfo.mobile = jsonLoginInfo.mobile;
          this._loginInfo.birthDate = jsonLoginInfo.birthDate;
          this._loginInfo.originApp = jsonLoginInfo.originApp;
          this._loginInfo.idUserDocumentType = jsonLoginInfo.idUserDocumentType;
          this._loginInfo.documentNumber = jsonLoginInfo.documentNumber;
          this._loginInfo.access_token = jsonLoginInfo.access_token;
          this._loginInfo.expires_in = jsonLoginInfo.expires_in;
          this._loginInfo.token_type = jsonLoginInfo.token_type;
          this._loginInfo.permissions = jsonLoginInfo.permissions;
          this._loginInfo.idBusinessPartner = jsonLoginInfo.idBusinessPartner;
          this._loginInfo.userRoleTypeCodes = jsonLoginInfo.userRoleTypeCodes;
          this.authResponseSubject.next(this._loginInfo);
          resolve(this._loginInfo);
        } else {
          this.authResponseSubject.next(null);
          resolve(null);
        }
      });
    });
    return promesa;
  }
}
