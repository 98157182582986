import { Injectable } from '@angular/core';
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  ActivatedRoute
} from '@angular/router';
import { Observable } from 'rxjs';
import { LoginInformation } from '../models/login-information.model';
import { map, take } from 'rxjs/operators';
import { IMenuOption } from '../models/imenu-option.interface';
import { AuthService } from '../services/auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(
    private authService: AuthService,
    private router: Router // private route: ActivatedRoute
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    router: RouterStateSnapshot
  ):
    | boolean
    | UrlTree
    | Promise<boolean | UrlTree>
    | Observable<boolean | UrlTree> {
    return this.authService
      .cargarStorage2()
      .then((loginInfo: LoginInformation) => {
        //this.router.config[0].redirectTo = "user-account"
        const isAuth = !!loginInfo;
        if (!isAuth && route.routeConfig.path !== 'auth')
          return this.router.createUrlTree(['/auth']);
        if (isAuth && route.routeConfig.path === 'auth')
          return this.router.createUrlTree(['/']);
        if (
          isAuth &&
          !loginInfo.permissions.find((value) => {
            return value.menuOptionUrl === `/${route.routeConfig.path}`;
          })
        )
          return this.router.createUrlTree(['/']);
        return true;
      });
  }
}
