import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { IAuthResponse } from '../../models/iauth-response.interface';

@Injectable({
  providedIn: 'root'
})
export class AuthRepositoryService {
  constructor(private http: HttpClient) {}

  login(username: string, password: string) {
    let url = environment.urlApi + 'token';
    return this.http.post<IAuthResponse>(
      url,
      new HttpParams()
        .append('grant_type', 'password')
        .append('username', username)
        .append('password', password)
        .append('originApp', 'P')
    );
  }

  resetPassword(username: string) {
    let url =
      environment.urlApi + 'security/passwordRecovery?userAccount=' + username;

    return this.http.get(url);
  }

  approve(userAccount: string) {
    let url = environment.urlApi + 'user/approve?userAccount=' + userAccount;

    return this.http.get(url);
  }
}
