import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { IonicStorageModule } from '@ionic/storage';
import { AgmCoreModule } from '@agm/core';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { BarcodeScanner } from '@ionic-native/barcode-scanner/ngx';
import { AuthInterceptorService } from './auth-interceptor.service';
// import { FCM } from '@ionic-native/fcm/ngx';

// import { AngularFireModule } from '@angular/fire';
// import { AngularFirestoreModule } from '@angular/fire/firestore';
// import { AngularFireStorageModule } from '@angular/fire/storage';
// import { AngularFireAuthModule } from '@angular/fire/auth';
// import { environment } from '../environments/environment';
import { FormsModule } from '@angular/forms';
import { ItemsComponent } from './components/items/items.component';
import { environment } from '../environments/environment';

import { OneSignal } from '@ionic-native/onesignal/ngx';
import { LockerPointComponent } from './components/locker-point/locker-point.component';
import { UsersComponent } from './components/users/users.component';
import { WorkOrderStatusComponent } from './components/work-order-status/work-order-status.component';
import { ServiceWorkerModule } from '@angular/service-worker';

@NgModule({
  declarations: [
    AppComponent,
    ItemsComponent,
    LockerPointComponent,
    UsersComponent,
    WorkOrderStatusComponent
  ],
  entryComponents: [
    ItemsComponent,
    LockerPointComponent,
    UsersComponent,
    WorkOrderStatusComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    FormsModule,
    IonicModule.forRoot(),
    IonicStorageModule.forRoot(),
    AppRoutingModule,
    AgmCoreModule.forRoot({
      apiKey: environment.gmapKey
    }),
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })
    // ,
    // AngularFireModule.initializeApp(environment.firebaseConfig),
    // AngularFirestoreModule, // firestore
    // AngularFireAuthModule, // auth
    // AngularFireStorageModule // storage
  ],
  providers: [
    StatusBar,
    SplashScreen,
    BarcodeScanner,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptorService,
      multi: true
    },
    OneSignal
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
