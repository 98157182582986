import { Component, OnInit } from '@angular/core';

import { Platform, LoadingController, AlertController } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import {
  Router,
  RouterEvent,
  NavigationStart,
  NavigationEnd,
  NavigationCancel,
  NavigationError
} from '@angular/router';
// import { FCM } from '@ionic-native/fcm/ngx';
// import { Plugins, PushNotification } from '@capacitor/core';
// const { PushNotifications } = Plugins;

// with type support
import { IMenuOption } from './models/imenu-option.interface';
import { Subscription } from 'rxjs';
import { LoginInformation } from './models/login-information.model';
import { AuthService } from './services/auth/auth.service';
import { filter } from 'rxjs/operators';
import { LoadingService } from './services/common/loading.service';
import { OneSignal } from '@ionic-native/onesignal/ngx';
import { environment } from '../environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit {
  private authSubscription: Subscription;
  // notifications: PushNotification[] = [];
  topicName = 'delivery';
  remoteToken: string;
  menuItems: IMenuOption[] = [];

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private authService: AuthService,
    private router: Router, // , // private fcm: FCM,
    private loadingService: LoadingService,
    private alertCtrl: AlertController,
    private oneSignal: OneSignal
  ) {
    this.initializeApp();
    // this.router.events
    //   .pipe(
    //     filter((e, index) => {
    //       return e instanceof RouterEvent;
    //     })
    //   )
    //   .subscribe(async (event: RouterEvent) => {
    //     switch (true) {
    //       case event instanceof NavigationStart: {
    //         await this.loadingService.loadingPresent()
    //         break;
    //       }

    //       case event instanceof NavigationEnd:
    //       case event instanceof NavigationCancel:
    //       case event instanceof NavigationError: {
    //         await this.loadingService.loadingDismiss();
    //         break;
    //       }
    //       default: {
    //         break;
    //       }
    //     }
    //   });
  }

  ngOnInit() {
    this.authSubscription = this.authService.authResponseSubject.subscribe(
      (loginInfo: LoginInformation) => {
        if (loginInfo && loginInfo.permissions) {
          this.menuItems = loginInfo.permissions.sort((a, b) => {
            return a.order - b.order;
          });
        }
      }
    );
    // this.authService.getMenu('jvalencia3005@gmail.com').subscribe();
    // this.authService.autoLogin()
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();

      // this.authService.cargar_storage();

      if (!this.platform.is('capacitor')) {
        //
        this.authService._tokenFCM = 'PWA';
      } else {
        this.setupPush();
        // PushNotifications.addListener('registration', (data) => {
        //   // alert(JSON.stringify(data));
        //   console.log(data);
        // });

        // PushNotifications.register()
        //   .then(() => {
        //     console.log(`registered for push`);
        //     // this.getToken();
        //   })
        //   .catch((err) => console.log(JSON.stringify(err)));

        // PushNotifications.addListener(
        //   'pushNotificationReceived',
        //   (notification: PushNotification) => {
        //     console.log('notification ' + JSON.stringify(notification));
        //     this.notifications.push(notification);
        //   }
        // );
      }
    });
  }

  onLogout() {
    this.authService.logout();
  }

  setupPush() {
    // I recommend to put these into your environment.ts
    this.oneSignal.startInit(
      environment.onesignal_appid,
      environment.firebaseConfig.messagingSenderId
    );

    this.oneSignal.inFocusDisplaying(
      // this.oneSignal.OSInFocusDisplayOption.None
      this.oneSignal.OSInFocusDisplayOption.InAppAlert
    );

    // Notifcation was received in general
    this.oneSignal.handleNotificationReceived().subscribe((data) => {
      // let msg = data.payload.body;
      // let title = data.payload.title;
      // let additionalData = data.payload.additionalData;
      // this.showAlert(title, msg, additionalData.task);
    });

    // Notification was really clicked/opened
    this.oneSignal.handleNotificationOpened().subscribe((data) => {
      // Just a note that the data is a different place here!
      // let additionalData = data.notification.payload.additionalData;
      // this.showAlert(
      //   'Notification opened',
      //   'You already read this before',
      //   additionalData.task
      // );
    });

    // Obtener Id del Suscriptor
    this.oneSignal.getIds().then((info) => {
      this.authService._tokenFCM = info.userId;
      this.authService._userIdOneSignal = info.pushToken;

      console.log('Obtener Id del Suscriptor', info);
    });

    this.oneSignal.endInit();
  }

  async showAlert(title, msg, task) {
    const alert = await this.alertCtrl.create({
      header: title,
      subHeader: msg,
      buttons: [
        {
          text: `Action: ${task}`,
          handler: () => {
            // E.g: Navigate to a specific screen
          }
        }
      ]
    });
    alert.present();
  }

  // subscribeTo() {
  //   PushNotifications.register()
  //     .then((_) => {
  //       fcm
  //         .subscribeTo({ topic: this.topicName })
  //         .then((r) => console.log(`subscribed to topic ${this.topicName}`))
  //         .catch((err) => console.log(err));
  //     })
  //     .catch((err) => console.log(JSON.stringify(err)));
  // }

  // unsubscribeFrom() {
  //   fcm
  //     .unsubscribeFrom({ topic: 'test' })
  //     .then((r) => console.log(`unsubscribed from topic ${this.topicName}`))
  //     .catch((err) => console.log(err));
  //   if (this.platform.is('android')) {
  //     fcm.deleteInstance();
  //   }
  // }

  // getToken() {
  //   // Get FCM token instead the APN one returned by Capacitor
  //   fcm
  //     .getToken()
  //     .then((result) => {
  //       this.remoteToken = result.token;
  //       this.authService._tokenFCM = result.token;
  //     })
  //     .catch((err) => console.log(err));
  // }
}
