import { UserCard } from './user-card.model';
import { IUser } from './iuser.interface';
import { IAuthResponse } from './iauth-response.interface';
import { IMenuOption } from './imenu-option.interface';
import { IUserRoleType } from './iuser-role-type.interface';
export class LoginInformation implements IUser, IAuthResponse {
  public idUser: number;
  public userAccount: string;
  public firstName: string;
  public lastName: string;
  public email: string;
  public password: string;
  public phone: string;
  public mobile: string;
  public originApp: string;
  public rolType: string;
  //public tarjetas: UserCard[]
  public access_token: string;
  public expires_in: number;
  public token_type: string;
  public isActive: boolean;
  public birthDate: Date;
  public idUserDocumentType: number;
  public documentNumber: string;
  public businessDocumentNumber: string;
  public permissions: IMenuOption[];
  public userRolesTypes: IUserRoleType[];

  public idBusinessPartner: number;
  public businessPartnerCompleteName: string;
  public userRoleTypeCodes: string;

  public constructor(init?: Partial<LoginInformation>) {
    Object.assign(this, init);
  }
}
