import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Order } from '../../models/order.model';
import { OrderDetail } from '../../models/order-detail.model';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { ToastController, AlertController } from '@ionic/angular';
import { environment } from 'src/environments/environment';
import { AuthService } from '../auth/auth.service';
import { WorkOrderStatus } from '../../models/work-order-status.model';
import { ItemType } from '../../models/item-type.model';
import { ActivityType } from '../../models/activity-type.model';
import { ServiceType } from '../../models/service-type.model';

@Injectable({
  providedIn: 'root'
})
export class OrdersService {
  _orders: Order[] = [];
  _work_order_status: WorkOrderStatus[] = [];
  searchText: string = '';
  page: number = 0;
  take: number = 10;
  total: number;
  contador = 0;
  private _serviceTypes: ServiceType[] = [];
  private _activityTypes: ActivityType[] = [];
  private _itemTypes: ItemType[] = [];

  constructor(
    private http: HttpClient,
    private toastCtrl: ToastController,
    private alertCtrl: AlertController,
    private authService: AuthService
  ) {}

  get orders() {
    return this._orders;
  }

  canSearch(): boolean {
    return !this.total || this.total > this.page * this.take;
  }

  resetSearch(searchText: string, idWorkOrderStatus: number) {
    this.searchText = searchText;
    this._orders = [];
    this.total = null;
    this.page = 0;
    if (!this.canSearch()) {
      return;
    }
    this.page++;
    // this.getOrders(this.searchText, idWorkOrderStatus);
    return this.getOrders(this.searchText, idWorkOrderStatus);
  }

  search(searchText: string, idWorkOrderStatus: number) {
    if (this.searchText !== searchText) {
      this.searchText = searchText;
      this._orders = [];
      this.total = null;
      this.page = 0;
    }
    if (!this.canSearch()) {
      return;
    }
    this.page++;
    // this.getOrders(this.searchText, idWorkOrderStatus);
    return this.getOrders(this.searchText, idWorkOrderStatus);
  }

  private getOrders(searchText: string, idWorkOrderStatus: number) {
    const promesa = new Promise((resolve, reject) => {
      const url =
        environment.urlApi +
        'WorkOrder/getAllWorkOrderByWorkOrderStatusAndUser?page=' +
        this.page +
        '&take=' +
        this.take +
        '&idWorkOrderStatus=' +
        idWorkOrderStatus +
        '&idUser=' +
        this.authService.getUser().idUser +
        '&searchText=' +
        (searchText ? searchText : '');

      const httpOptions = {
        headers: new HttpHeaders({
          Accept: 'application/json',
          Authorization: 'Bearer ' + this.authService.getUser().access_token
        })
      };

      this.http.get(url, httpOptions).subscribe(
        async (res: any) => {
          // console.log(res);
          const resultado = res;

          if (!resultado.data) {
            this._orders = [];
            resolve();
          } else {
            // si se obtienen los datos del usuario
            this._orders = [...this._orders, ...resultado.data];
            this.total = resultado.total;
            resolve();
          }
        },
        async (error) => {
          // handle error
          console.log('Error en servicio', error);

          const alert = await this.alertCtrl.create({
            header: 'Error',
            subHeader: 'No se pudo cargar la información', // data_resp["api_message"],
            buttons: ['OK']
          });

          await alert.present();

          resolve();
        }
      );
    });

    return promesa;
  }

  // getOrder(id: number) {
  //   return { ...this._orders.find((p) => p.idWorkOrder === id) };
  // }

  getOrder(id: number) {
    const promesa = new Promise((resolve, reject) => {
      const url =
        environment.urlApi +
        'WorkOrder/getWorkOrderInformation?idWorkOrder=' +
        id.toString();

      const httpOptions = {
        headers: new HttpHeaders({
          Accept: 'application/json',
          Authorization: 'Bearer ' + this.authService.getUser().access_token
        })
      };

      this.http.get(url, httpOptions).subscribe(
        async (res: any) => {
          // console.log(res);
          const resultado = res;

          // if (!resultado.data) {
          //   this._work_order_status = [];
          //   resolve();
          // } else {
          //   // si se obtienen los datos del usuario
          //   this._orders = [...this._orders, ...resultado.data];
          resolve(resultado.data);
          // }
        },
        async (error) => {
          // handle error
          console.log('Error en servicio', error);

          const alert = await this.alertCtrl.create({
            header: 'Error',
            subHeader: 'No se pudo cargar la información', // data_resp["api_message"],
            buttons: ['OK']
          });

          await alert.present();

          resolve(null);
        }
      );
    });

    return promesa;
  }

  approveOrder(id: number, detalle: any) {
    const promesa = new Promise((resolve, reject) => {
      const url =
        environment.urlApi +
        'WorkOrder/acceptWO?idUser=' +
        this.authService.getUser().idUser +
        '&idWorkOrder=' +
        id.toString();

      const httpOptions = {
        headers: new HttpHeaders({
          Accept: 'application/json',
          Authorization: 'Bearer ' + this.authService.getUser().access_token
        })
      };

      this.http.post(url, detalle, httpOptions).subscribe(
        async (res: any) => {
          console.log(res);
          const resultado = res;

          if (!resultado.isValid) {
            console.log('error.....');
            const alert = await this.alertCtrl.create({
              header: 'Error',
              subHeader: res.message,
              buttons: ['OK']
            });

            await alert.present();
            resolve();
          } else {
            const alert = await this.alertCtrl.create({
              header: 'Confirmación',
              subHeader: res.message,
              buttons: ['OK']
            });

            await alert.present();
            resolve();
          }
        },
        async (error) => {
          // handle error
          console.log('Error en servicio', error);

          const alert = await this.alertCtrl.create({
            header: 'Error',
            subHeader: 'No se pudo realizar la acción', // data_resp["api_message"],
            buttons: ['OK']
          });

          await alert.present();

          resolve();
        }
      );
    });

    return promesa;
  }

  declineOrder(id: number, comment: string) {
    const promesa = new Promise((resolve, reject) => {
      const url =
        environment.urlApi +
        'workorder/declineWO?idUser=' +
        this.authService.getUser().idUser +
        '&idWorkOrder=' +
        id.toString() +
        '&comment=' +
        comment;

      const httpOptions = {
        headers: new HttpHeaders({
          Accept: 'application/json',
          Authorization: 'Bearer ' + this.authService.getUser().access_token
        })
      };

      this.http.post(url, null, httpOptions).subscribe(
        async (res: any) => {
          // console.log(res);
          const resultado = res;

          if (!resultado.isValid) {
            const alert = await this.alertCtrl.create({
              header: 'Error',
              subHeader: res.message,
              buttons: ['OK']
            });

            await alert.present();
            resolve();
          } else {
            const alert = await this.alertCtrl.create({
              header: 'Confirmación',
              subHeader: res.message,
              buttons: ['OK']
            });

            await alert.present();
            resolve();
          }
        },
        async (error) => {
          // handle error
          console.log('Error en servicio', error);

          const alert = await this.alertCtrl.create({
            header: 'Error',
            subHeader: 'No se pudo realizar la acción', // data_resp["api_message"],
            buttons: ['OK']
          });

          await alert.present();

          resolve();
        }
      );
    });

    return promesa;
  }

  cancelOrder(id: number, comment: string) {
    const promesa = new Promise((resolve, reject) => {
      const url =
        environment.urlApi +
        'ServiceOrder/cancelSO?idServiceOrder=' +
        id.toString() +
        '&comment=' +
        comment;

      const httpOptions = {
        headers: new HttpHeaders({
          Accept: 'application/json',
          Authorization: 'Bearer ' + this.authService.getUser().access_token
        })
      };

      this.http.post(url, null, httpOptions).subscribe(
        async (res: any) => {
          // console.log(res);
          const resultado = res;

          if (!resultado.isValid) {
            const alert = await this.alertCtrl.create({
              header: 'Aviso',
              subHeader: res.message,
              buttons: ['OK']
            });

            await alert.present();
            resolve();
          } else {
            const alert = await this.alertCtrl.create({
              header: 'Confirmación',
              subHeader: res.message,
              buttons: ['OK']
            });

            await alert.present();
            resolve();
          }
        },
        async (error) => {
          // handle error
          console.log('Error en servicio', error);

          const alert = await this.alertCtrl.create({
            header: 'Aviso',
            subHeader: 'No se pudo realizar la acción', // data_resp["api_message"],
            buttons: ['OK']
          });

          await alert.present();

          resolve();
        }
      );
    });

    return promesa;
  }

  liquidateWO(id: number) {
    const promesa = new Promise((resolve, reject) => {
      const url =
        environment.urlApi +
        'WorkOrder/liquidateWO?idWorkOrder=' +
        id.toString();

      const httpOptions = {
        headers: new HttpHeaders({
          Accept: 'application/json',
          Authorization: 'Bearer ' + this.authService.getUser().access_token
        })
      };

      this.http.post(url, null, httpOptions).subscribe(
        async (res: any) => {
          // console.log(res);
          const resultado = res;

          if (!resultado.isValid) {
            const alert = await this.alertCtrl.create({
              header: 'Error',
              subHeader: res.message,
              buttons: ['OK']
            });

            await alert.present();
            reject();
          } else {
            const alert = await this.alertCtrl.create({
              header: 'Confirmación',
              subHeader: 'Orden liquidada',
              buttons: ['OK']
            });

            await alert.present();
            resolve();
          }
        },
        async (error) => {
          // handle error
          console.log('Error en servicio', error);

          const alert = await this.alertCtrl.create({
            header: 'Error',
            subHeader: 'No se pudo realizar la acción', // data_resp["api_message"],
            buttons: ['OK']
          });

          await alert.present();

          reject();
        }
      );
    });

    return promesa;
  }

  saveChangeWO(
    idWorkOrder: string,
    idLockerComplete: string,
    lockerPasswordComplete: string,
    comment: string
  ) {
    const promesa = new Promise((resolve, reject) => {
      const url =
        environment.urlApi +
        'WorkOrder/SaveChangeWO?idWorkOrder=' +
        idWorkOrder +
        '&idLockerComplete=' +
        idLockerComplete +
        '&lockerPasswordComplete=' +
        lockerPasswordComplete +
        '&comment=' +
        comment;

      const httpOptions = {
        headers: new HttpHeaders({
          Accept: 'application/json',
          Authorization: 'Bearer ' + this.authService.getUser().access_token
        })
      };

      this.http.post(url, null, httpOptions).subscribe(
        async (res: any) => {
          // console.log(res);
          const resultado = res;

          if (!resultado.isValid) {
            const alert = await this.alertCtrl.create({
              header: 'Error',
              subHeader: res.message,
              buttons: ['OK']
            });

            await alert.present();
            resolve();
          } else {
            const alert = await this.alertCtrl.create({
              header: 'Confirmación',
              subHeader: res.message,
              buttons: ['OK']
            });

            await alert.present();
            resolve();
          }
        },
        async (error) => {
          // handle error
          console.log('Error en servicio', error);

          const alert = await this.alertCtrl.create({
            header: 'Error',
            subHeader: 'No se pudo realizar la acción', // data_resp["api_message"],
            buttons: ['OK']
          });

          await alert.present();

          resolve();
        }
      );
    });

    return promesa;
  }

  restoreWO(idWorkOrder: string, comment: string) {
    const promesa = new Promise((resolve, reject) => {
      const url =
        environment.urlApi +
        'workOrder/RestoreWO?idWorkOrder=' +
        idWorkOrder +
        '&comment=' +
        comment;

      const httpOptions = {
        headers: new HttpHeaders({
          Accept: 'application/json',
          Authorization: 'Bearer ' + this.authService.getUser().access_token
        })
      };

      this.http.post(url, null, httpOptions).subscribe(
        async (res: any) => {
          // console.log(res);
          const resultado = res;

          if (!resultado.isValid) {
            const alert = await this.alertCtrl.create({
              header: 'Error',
              subHeader: res.message,
              buttons: ['OK']
            });

            await alert.present();
            resolve();
          } else {
            const alert = await this.alertCtrl.create({
              header: 'Confirmación',
              subHeader: res.message,
              buttons: ['OK']
            });

            await alert.present();
            resolve();
          }
        },
        async (error) => {
          // handle error
          console.log('Error en servicio', error);

          const alert = await this.alertCtrl.create({
            header: 'Error',
            subHeader: 'No se pudo realizar la acción', // data_resp["api_message"],
            buttons: ['OK']
          });

          await alert.present();

          resolve();
        }
      );
    });

    return promesa;
  }

  saveChangeWOAndDetail(workOrderWithDetails: any, comment: string) {
    const promesa = new Promise((resolve, reject) => {
      const url =
        environment.urlApi +
        'workOrder/SaveChangeWOAndDetail?comment=' +
        comment;

      const httpOptions = {
        headers: new HttpHeaders({
          Accept: 'application/json',
          Authorization: 'Bearer ' + this.authService.getUser().access_token
        })
      };

      this.http.post(url, workOrderWithDetails, httpOptions).subscribe(
        async (res: any) => {
          // console.log(res);
          const resultado = res;

          if (!resultado.isValid) {
            const alert = await this.alertCtrl.create({
              header: 'Error',
              subHeader: res.message,
              buttons: ['OK']
            });

            await alert.present();
            resolve();
          } else {
            const alert = await this.alertCtrl.create({
              header: 'Confirmación',
              subHeader: res.message,
              buttons: ['OK']
            });

            await alert.present();
            resolve();
          }
        },
        async (error) => {
          // handle error
          console.log('Error en servicio', error);

          const alert = await this.alertCtrl.create({
            header: 'Error',
            subHeader: 'No se pudo realizar la acción', // data_resp["api_message"],
            buttons: ['OK']
          });

          await alert.present();

          resolve();
        }
      );
    });

    return promesa;
  }

  startOrder(id: number) {
    const promesa = new Promise((resolve, reject) => {
      const url =
        environment.urlApi + 'workorder/startWO?idWorkOrder=' + id.toString();

      const httpOptions = {
        headers: new HttpHeaders({
          Accept: 'application/json',
          Authorization: 'Bearer ' + this.authService.getUser().access_token
        })
      };

      this.http.post(url, null, httpOptions).subscribe(
        async (res: any) => {
          // console.log(res);
          const resultado = res;

          if (!resultado.isValid) {
            const alert = await this.alertCtrl.create({
              header: 'Error',
              subHeader: res.message,
              buttons: ['OK']
            });

            await alert.present();
            resolve();
          } else {
            const alert = await this.alertCtrl.create({
              header: 'Confirmación',
              subHeader: res.message,
              buttons: ['OK']
            });

            await alert.present();
            resolve();
          }
        },
        async (error) => {
          // handle error
          console.log('Error en servicio', error);

          const alert = await this.alertCtrl.create({
            header: 'Error',
            subHeader: 'No se pudo realizar la acción', // data_resp["api_message"],
            buttons: ['OK']
          });

          await alert.present();

          resolve(null);
        }
      );
    });

    return promesa;
  }

  endOrder(id: number, idLocker: string, lockerPassword: string) {
    const promesa = new Promise((resolve, reject) => {
      const url =
        environment.urlApi +
        'WorkOrder/endWO?idWorkOrder=' +
        id.toString() +
        '&idLockerComplete=' +
        idLocker +
        '&lockerPasswordComplete=' +
        lockerPassword;

      const httpOptions = {
        headers: new HttpHeaders({
          Accept: 'application/json',
          Authorization: 'Bearer ' + this.authService.getUser().access_token
        })
      };

      this.http.post(url, null, httpOptions).subscribe(
        async (res: any) => {
          // console.log(res);
          const resultado = res;

          if (!resultado.isValid) {
            const alert = await this.alertCtrl.create({
              header: 'Error',
              subHeader: res.message,
              buttons: ['OK']
            });

            await alert.present();
            resolve();
          } else {
            const alert = await this.alertCtrl.create({
              header: 'Confirmación',
              subHeader: res.message,
              buttons: ['OK']
            });

            await alert.present();
            resolve();
          }
        },
        async (error) => {
          // handle error
          console.log('Error en servicio', error);

          const alert = await this.alertCtrl.create({
            header: 'Error',
            subHeader: 'No se pudo realizar la acción', // data_resp["api_message"],
            buttons: ['OK']
          });

          await alert.present();

          reject();
        }
      );
    });

    return promesa;
  }

  blockOrder(id: number, comment: string) {
    const promesa = new Promise((resolve, reject) => {
      const url =
        environment.urlApi +
        'WorkOrder/blockWO?idWorkOrder=' +
        id.toString() +
        '&comment=' +
        comment;

      const httpOptions = {
        headers: new HttpHeaders({
          Accept: 'application/json',
          Authorization: 'Bearer ' + this.authService.getUser().access_token
        })
      };

      this.http.post(url, null, httpOptions).subscribe(
        async (res: any) => {
          // console.log(res);
          const resultado = res;

          if (!resultado.isValid) {
            const alert = await this.alertCtrl.create({
              header: 'Error',
              subHeader: res.message,
              buttons: ['OK']
            });

            await alert.present();
            resolve();
          } else {
            const alert = await this.alertCtrl.create({
              header: 'Confirmación',
              subHeader: res.message,
              buttons: ['OK']
            });

            await alert.present();
            resolve();
          }
        },
        async (error) => {
          // handle error
          console.log('Error en servicio', error);

          const alert = await this.alertCtrl.create({
            header: 'Error',
            subHeader: 'No se pudo realizar la acción', // data_resp["api_message"],
            buttons: ['OK']
          });

          await alert.present();

          resolve(null);
        }
      );
    });

    return promesa;
  }

  updateSOLocker(id: number, idLocker: number, lockerPassword: string) {
    const promesa = new Promise((resolve, reject) => {
      const url =
        environment.urlApi +
        'ServiceOrder/UpdateLockerInformation?idServiceOrder=' +
        id.toString() +
        '&idLockerComplete=' +
        idLocker.toString() +
        '&lockerPasswordComplete=' +
        lockerPassword;

      const httpOptions = {
        headers: new HttpHeaders({
          Accept: 'application/json',
          Authorization: 'Bearer ' + this.authService.getUser().access_token
        })
      };

      this.http.post(url, null, httpOptions).subscribe(
        async (res: any) => {
          // console.log(res);
          const resultado = res;

          if (!resultado.isValid) {
            const alert = await this.alertCtrl.create({
              header: 'Error',
              subHeader: 'No se pudo registrar el locker seleccionado', // res.message,
              buttons: ['OK']
            });

            await alert.present();
            reject();
          } else {
            // const alert = await this.alertCtrl.create({
            //   header: 'Confirmación',
            //   subHeader: res.message,
            //   buttons: ['OK']
            // });

            // await alert.present();
            resolve();
          }
        },
        async (error) => {
          // handle error
          console.log('Error en servicio', error);

          const alert = await this.alertCtrl.create({
            header: 'Error',
            subHeader: 'No se pudo realizar la acción', // data_resp["api_message"],
            buttons: ['OK']
          });

          await alert.present();

          reject();
        }
      );
    });

    return promesa;
  }

  addOrder(order: Order) {
    this._orders.push(order);
  }

  removeOrder(order: Order) {
    this._orders = this._orders.filter((item) => {
      return item.idWorkOrder !== order.idWorkOrder;
    });
  }

  updateOrder(order: Order) {
    // this._orders.forEach(element => {
    //   if (order.idWorkOrder === element.idWorkOrder) {
    //     element = order;
    //     console.log('servicio', element);
    //   }
    // });
    this._orders = this._orders.filter((item) => {
      return item.idWorkOrder !== order.idWorkOrder;
    });

    this._orders.unshift(order);
  }

  padLeft(text: string, padChar: string, size: number): string {
    return (String(padChar).repeat(size) + text).substr(size * -1, size);
  }

  addDays(date: Date, days: number): Date {
    date.setDate(date.getDate() + days);
    return date;
  }

  get serviceTypes() {
    return this._serviceTypes;
  }

  get activityTypes() {
    return this._activityTypes;
  }

  get itemTypes() {
    return this._itemTypes;
  }

  getOrderStatus() {
    return this._work_order_status;
  }

  getAllOrderStatus() {
    const promesa = new Promise((resolve, reject) => {
      const url = environment.urlApi + 'WorkOrder/getAllWorkOrderStatusForApp';
      // 'MasterData/getAllEntityStatusTypeActiveByEntityCode/WOS';

      const httpOptions = {
        headers: new HttpHeaders({
          Accept: 'application/json',
          Authorization: 'Bearer ' + this.authService.getUser().access_token
        })
      };

      // console.log(this.authService.getUser().access_token);

      this.http.get(url, httpOptions).subscribe(
        async (res: any) => {
          // console.log(res);
          const resultado = res;

          if (!resultado.data) {
            this._work_order_status = [];
            resolve();
          } else {
            // si se obtienen los datos del usuario
            this._work_order_status = [];
            this._work_order_status.push(...resultado.data);
            resolve();
          }
        },
        async (error) => {
          // handle error
          console.log('Error en servicio', error);

          const alert = await this.alertCtrl.create({
            header: 'Error',
            subHeader: 'No se pudo cargar la información', // data_resp["api_message"],
            buttons: ['OK']
          });

          await alert.present();

          resolve();
        }
      );
    });

    return promesa;
  }

  getLocker(lockerNumber: string) {
    const promesa = new Promise((resolve, reject) => {
      const url =
        environment.urlApi +
        'locker/getLockerByLockernumber?lockerNumber=' +
        lockerNumber;

      const httpOptions = {
        headers: new HttpHeaders({
          Accept: 'application/json',
          Authorization: 'Bearer ' + this.authService.getUser().access_token
        })
      };

      this.http.get(url, httpOptions).subscribe(
        async (res: any) => {
          // console.log(res);
          const resultado = res;

          if (!resultado.data) {
            // const alert = await this.alertCtrl.create({
            //   header: 'Error',
            //   subHeader:
            //     'No se encontró el número de locker. Ingrese nuevamente el código',
            //   buttons: ['OK']
            // });

            // await alert.present();

            const myToast = this.toastCtrl
              .create({
                message:
                  'No se encontró el número de locker. Ingrese nuevamente el código',
                duration: 5000
              })
              .then((toastData) => {
                toastData.present();
              });

            reject();
          } else {
            resolve(resultado.data);
          }
        },
        async (error) => {
          // handle error
          console.log('Error en servicio', error);

          const alert = await this.alertCtrl.create({
            header: 'Error',
            subHeader: 'No se pudo cargar la información', // data_resp["api_message"],
            buttons: ['OK']
          });

          await alert.present();

          reject();
        }
      );
    });

    return promesa;
  }

  validateLockerDestination(lockerNumber: string, idDestination: number) {
    const promesa = new Promise((resolve, reject) => {
      const url =
        environment.urlApi +
        'Locker/getLockerByLockernumberAndDestination?lockerNumber=' +
        lockerNumber +
        '&idDestination=' +
        idDestination;

      const httpOptions = {
        headers: new HttpHeaders({
          Accept: 'application/json',
          Authorization: 'Bearer ' + this.authService.getUser().access_token
        })
      };

      this.http.get(url, httpOptions).subscribe(
        async (res: any) => {
          // console.log(res);
          const resultado = res;

          if (!resultado.isValid) {
            // const alert = await this.alertCtrl.create({
            //   header: 'Error',
            //   subHeader:
            //     'No se encontró el número de locker. Ingrese nuevamente el código',
            //   buttons: ['OK']
            // });

            // await alert.present();

            const myToast = this.toastCtrl
              .create({
                message: resultado.message,
                duration: 5000
              })
              .then((toastData) => {
                toastData.present();
              });

            reject();
          } else {
            resolve(resultado.data);
          }
        },
        async (error) => {
          // handle error
          console.log('Error en servicio', error);

          const alert = await this.alertCtrl.create({
            header: 'Error',
            subHeader: 'No se pudo cargar la información', // data_resp["api_message"],
            buttons: ['OK']
          });

          await alert.present();

          reject();
        }
      );
    });

    return promesa;
  }

  getActivityTypes(serviceTypeCode: string) {
    const promesa = new Promise((resolve, reject) => {
      const url =
        environment.urlApi +
        'ServiceType/getAllActivityTypeActiveByServiceType?serviceCode=' +
        serviceTypeCode;

      const httpOptions = {
        headers: new HttpHeaders({
          Accept: 'application/json',
          Authorization: 'Bearer ' + this.authService.getUser().access_token
        })
      };

      this.http.get(url, httpOptions).subscribe(
        async (res: any) => {
          // console.log(res);
          const resultado = res;

          if (!resultado.data) {
            this._activityTypes = [];
            resolve();
          } else {
            // si se obtienen los datos del usuario
            this._activityTypes = [];
            this._activityTypes.push(...resultado.data);
            resolve();
          }
        },
        async (error) => {
          // handle error
          console.log('Error en servicio', error);

          const alert = await this.alertCtrl.create({
            header: 'Error',
            subHeader: 'No se pudo cargar la información', // data_resp["api_message"],
            buttons: ['OK']
          });

          await alert.present();

          resolve();
        }
      );
    });

    return promesa;
  }

  getItemTypes(
    activityTypeCode: string,
    idServiceType: number,
    idUser: number
  ) {
    const promesa = new Promise((resolve, reject) => {
      const url =
        environment.urlApi +
        'serviceType/getAllItemTypeActiveByActivityTypeAndServiceTypeAndUser?activityTypeCode=' +
        activityTypeCode +
        '&idServiceType=' +
        idServiceType.toString() +
        '&idUser=' +
        idUser.toString();

      const httpOptions = {
        headers: new HttpHeaders({
          Accept: 'application/json',
          Authorization: 'Bearer ' + this.authService.getUser().access_token
        })
      };

      this.http.get(url, httpOptions).subscribe(
        async (res: any) => {
          // console.log(res);
          const resultado = res;

          if (!resultado.data) {
            this._itemTypes = [];
            resolve();
          } else {
            // si se obtienen los datos del usuario
            this._itemTypes = [];
            this._itemTypes.push(...resultado.data);
            resolve();
          }
        },
        async (error) => {
          // handle error
          console.log('Error en servicio', error);

          const alert = await this.alertCtrl.create({
            header: 'Error',
            subHeader: 'No se pudo cargar la información', // data_resp["api_message"],
            buttons: ['OK']
          });

          await alert.present();

          resolve();
        }
      );
    });

    return promesa;
  }

  getWorkOrderStatusLog(id: number) {
    const promesa = new Promise((resolve, reject) => {
      const url =
        environment.urlApi +
        'workorder/GetWorkOrderStatusLogByWorkOrder?idWorkOrder=' +
        id.toString();

      const httpOptions = {
        headers: new HttpHeaders({
          Accept: 'application/json',
          Authorization: 'Bearer ' + this.authService.getUser().access_token
        })
      };

      this.http.get(url, httpOptions).subscribe(
        async (res: any) => {
          // console.log(res);
          const resultado = res;

          // if (!resultado.data) {
          //   this._work_order_status = [];
          //   resolve();
          // } else {
          //   // si se obtienen los datos del usuario
          //   this._orders = [...this._orders, ...resultado.data];
          resolve(resultado.data);
          // }
        },
        async (error) => {
          // handle error
          console.log('Error en servicio', error);

          const alert = await this.alertCtrl.create({
            header: 'Error',
            subHeader: 'No se pudo cargar la información', // data_resp["api_message"],
            buttons: ['OK']
          });

          await alert.present();

          resolve(null);
        }
      );
    });

    return promesa;
  }
}
