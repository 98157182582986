import { Component, OnInit, Input } from '@angular/core';
import { ModalController, LoadingController } from '@ionic/angular';
import { NgForm } from '@angular/forms';
import { Order } from '../../models/order.model';
import { ActivityType } from './../../models/activity-type.model';
import { OrdersService } from '../../services/order/orders.service';
import { ItemType } from '../../models/item-type.model';
import { OrderDetail } from '../../models/order-detail.model';
import { AuthService } from '../../services/auth/auth.service';

@Component({
  selector: 'app-items',
  templateUrl: './items.component.html',
  styleUrls: ['./items.component.scss']
})
export class ItemsComponent implements OnInit {
  @Input() activityType: ActivityType;
  @Input() newOrder: Order;
  public isUserDataLoaded = false;

  public items: any = [];

  constructor(
    private modalCtrl: ModalController,
    private loadingCtrl: LoadingController,
    private ordersService: OrdersService,
    private authService: AuthService
  ) {}

  ngOnInit() {}

  async ionViewWillEnter() {
    // console.log('ionViewWillEnter');

    const loading = await this.loadingCtrl.create({
      keyboardClose: true,
      message: 'Cargando datos...'
    });
    await loading.present();

    this.ordersService
      .getItemTypes(
        this.activityType.code,
        this.activityType.idServiceType,
        this.authService.getUser().idUser
      )
      .then(() => {
        this.ordersService.itemTypes
          .sort((a, b) => a.description.localeCompare(b.description))
          .forEach((element) => {
            const item: any = {
              itemType: element,
              val: element.description,
              isChecked: false,
              isDisabled: this.isSelected(element)
            };

            this.items.push(item);
          });

        this.isUserDataLoaded = true;
        loading.dismiss();
      });
  }

  isSelected(itemType: ItemType) {
    // console.log(this.newOrder.detalle);
    // return this.newOrder.detalle.find(
    return this.newOrder.workOrderDetails.find(
      (p) => p.idItemType === itemType.idItemType
    )
      ? true
      : false;
  }

  onDismiss() {
    // using the injected ModalController this page
    // can "dismiss" itself and optionally pass back data
    this.modalCtrl.dismiss({
      dismissed: true
    });
  }

  onSelect() {
    this.items.forEach((element) => {
      if (element.isChecked) {
        const detalle = new OrderDetail();
        detalle.unitPrice = element.itemType.unitPrice;
        if (detalle.unitPrice == null) {
          detalle.unitPrice = 0;
        }
        detalle.quantity = 1;
        // detalle.descripcion = element.val;
        detalle.itemTypeDescription = element.val;
        detalle.itemTypeShortDescription = element.itemType.shortDescription;
        detalle.idWorkOrder = this.newOrder.idWorkOrder;
        detalle.idTariffDetail = element.itemType.idTariffDetail;
        detalle.idItemType = element.itemType.idItemType;
        detalle.idActivityType = element.itemType.idActivityType;
        detalle.activityTypeCode = element.itemType.activityTypeCode;
        detalle.activityTypeDescription =
          element.itemType.activityTypeDescription;
        detalle.idServiceType = this.activityType.idServiceType;
        detalle.serviceTypeCode = this.activityType.serviceTypeCode;
        detalle.serviceTypeName = this.activityType.serviceTypeName;
        detalle.idWorkOrderDetail = 0;
        detalle.baseTariff = element.itemType.baseTariff;
        // this.newOrder.detalle.unshift(detalle);
        this.newOrder.workOrderDetails.unshift(detalle);
        // this.ordersService.sumOrderTotalAmount();  LA SUMATORIA SE HACE EN LA VISTA PADRE
      }
    });

    this.modalCtrl.dismiss({
      dismissed: true
    });
  }
}
