export class OrderDetail {
  public idWorkOrderDetail: number;
  public idWorkOrder: number;
  public idActivityType: number;
  public activityTypeCode: string;
  public activityTypeDescription: string;
  public idServiceType: number;
  public serviceTypeCode: string;
  public serviceTypeName: string;
  public serviceTypeIcon: string;
  public idItemType: number;
  public itemTypeShortDescription: string;
  public itemTypeDescription: string;
  public quantity: number;
  public unitPrice: number;
  public baseTariff: number;
  public totalPrice: number;
  public idTariffDetail: number;
  public costPrice: number;
  public totalCost: number;
  public idCostDetail: number;

  public constructor(init?: Partial<OrderDetail>) {
    Object.assign(this, init);
  }

  // constructor(
  //     public id: number,
  //     public descripcion: string,
  //     public cantidad: number
  // ) {}
}
