export class ActivityType {
  constructor(
    public idActivityType: number,
    public idServiceType: number,
    public code: string,
    public description: string,
    public serviceTypeCode: string,
    public serviceTypeName: string,
    public isDefault: boolean,
    public isOptional: boolean
  ) {}
}
