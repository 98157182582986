import { Component, OnInit, ViewChild } from '@angular/core';
import { IonInfiniteScroll, ModalController } from '@ionic/angular';
import { FiltersService } from '../../services/filters/filters.service';
import { WorkOrderStatus } from '../../models/work-order-status.model';

@Component({
  selector: 'app-work-order-status',
  templateUrl: './work-order-status.component.html',
  styleUrls: ['./work-order-status.component.scss']
})
export class WorkOrderStatusComponent implements OnInit {
  @ViewChild(IonInfiniteScroll, null) infiniteScroll: IonInfiniteScroll;
  searchText: string = '';
  isFirstSearching = false;
  isSearching = false;
  workOrderStatuses: WorkOrderStatus[] = [];
  skeleton = [];

  constructor(
    private modalCtrl: ModalController,
    private filterService: FiltersService
  ) {}

  ngOnInit() {
    this.filterService.workOrderStatuses = [];
    this.filterService.page = 0;
    this.filterService.total = 0;
    this.isFirstSearching = true;
    this.infiniteScroll.disabled = true;
    this.isSearching = true;
    this.skeleton = this.getSkeletonQuantity();
    this.filterService.searchStatuses('').then((result) => {
      this.workOrderStatuses = this.filterService.workOrderStatuses;
      this.isFirstSearching = false;
      this.isSearching = false;
      this.infiniteScroll.complete();
      this.infiniteScroll.disabled = !this.filterService.canSearch();
    });
  }

  dismissModal(statusSelected?) {
    // using the injected ModalController this page
    // can "dismiss" itself and optionally pass back data
    this.modalCtrl.dismiss({
      // tslint:disable-next-line:object-literal-shorthand
      statusSelected: statusSelected
    });
  }

  onSearchEvent(event) {
    this.isFirstSearching = true;
    this.infiniteScroll.disabled = true;
    this.workOrderStatuses = [];
    this.searchText = event.detail.value;
    this.search();
  }

  search() {
    this.isSearching = true;
    this.filterService.searchStatuses(this.searchText).then((result) => {
      this.workOrderStatuses = this.filterService.workOrderStatuses;
      this.isFirstSearching = false;
      this.isSearching = false;
      this.infiniteScroll.complete();
      this.infiniteScroll.disabled = !this.filterService.canSearch();
    });
  }

  getSkeletonQuantity() {
    let aux: number[] = [];

    for (let i = 0; i < 10; i++) {
      aux.push(i);
    }
    return aux;
  }

  selectRecord(statusSelected) {
    this.dismissModal(statusSelected);
  }
}
