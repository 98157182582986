import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { map } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class MasterTableRepositoryService {
  constructor(private http: HttpClient) {}

  getMasterTable(entityCode: string) {
    let url = environment.urlApi + "MasterData/GetMasterTablesByEntityCode/" + entityCode;

    return this.http.get(url).pipe(
      map((data: any) => {
        if (data.isValid && data.data) return data.data;
        return null;
      })
    );
  }
}
