import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { map } from "rxjs/operators";
import { IUser } from "../../models/iuser.interface";

@Injectable({
  providedIn: 'root'
})
export class UserRepositoryService {
  constructor(private http: HttpClient) {}
  register(usuario: any) {
    let url = environment.urlApi + 'user/create';

    return this.http.post(url, usuario);
  }

  update(usuario: any) {
    let url = environment.urlApi + 'user/update';

    return this.http.post(url, usuario);
  }

  new(usuario: any) {
    let url = environment.urlApi + 'user/new';

    return this.http.post(url, usuario);
  }

  delete(userId: number) {
    let url = environment.urlApi + 'user/delete/' + userId;

    return this.http.post(url, null);
  }

  getUser(userName: string) {
    let url =
      environment.urlApi + 'user/getByUserAccount?userAccount=' + userName;

    return this.http.get<IUser>(url).pipe(
      map((data: any) => {
        if (data.isValid && data.data) return data.data;
        return null;
      })
    );
  }

  getUsers(page: number, take: number, searchText: string) {
    let url = environment.urlApi + "user/users";

    return this.http
      .get<{ IsValid: boolean; Data: IUser[]; Total: number }>(url, {
        params: new HttpParams()
          .append("page", page.toString())
          .append("take", take.toString())
          .append("searchText", searchText ? searchText : ""),
      })
      .pipe(
        map((data: any) => {
          if (data.isValid && data.data) return data;
          return null;
        })
      );
  }
}
