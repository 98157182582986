import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { ToastController, AlertController } from '@ionic/angular';
import { AuthService } from '../auth/auth.service';
import { User } from '../../models/user.interface';
import { WorkOrderStatus } from '../../models/work-order-status.model';
import { LockerPoint } from '../../models/locker-point.interface';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FiltersService {
  users: User[] = [];
  workOrderStatuses: WorkOrderStatus[] = [];
  lockerPoints: LockerPoint[] = [];
  searchText: string = '';
  page: number = 0;
  take: number = 20;
  total: number;
  contador = 0;

  constructor(
    private http: HttpClient,
    private toastCtrl: ToastController,
    private alertCtrl: AlertController,
    private authService: AuthService
  ) {}

  canSearch(): boolean {
    return !this.total || this.total > this.page * this.take;
  }

  searchUsers(searchText: string) {
    if (this.searchText !== searchText) {
      this.searchText = searchText;
      this.users = [];
      this.total = null;
      this.page = 0;
    }
    if (!this.canSearch()) {
      // this.businessPartnerSubject.next(this.businessPartners)
      return;
    }
    this.page++;
    return this.getUsers(this.searchText);
  }

  private getUsers(searchText: string) {
    const promesa = new Promise((resolve, reject) => {
      // const url = environment.urlApi + 'WorkOrderSearch/GetUsersByFilter';
      const url = environment.urlApi + 'WorkOrderSearch/GetCustomersByFilter';

      const httpOptions = {
        headers: new HttpHeaders({
          Accept: 'application/json',
          Authorization: 'Bearer ' + this.authService.getUser().access_token
        }),
        params: new HttpParams()
          .append('page', this.page.toString())
          .append('take', this.take.toString())
      };

      const searchObject = {
        // tslint:disable-next-line:object-literal-shorthand
        searchText: searchText
      };

      this.http.post(url, searchObject, httpOptions).subscribe(
        async (res: any) => {
          // console.log(res);
          const resultado = res;

          if (!resultado.data) {
            this.users = [];
            resolve();
          } else {
            this.users = [...this.users, ...resultado.data];
            this.total = resultado.total;
            resolve();
          }
        },
        async (error) => {
          this.users = [];
          console.log('Error en servicio', error);

          const alert = await this.alertCtrl.create({
            header: 'Error',
            subHeader: 'No se pudo cargar la información', // data_resp["api_message"],
            buttons: ['OK']
          });

          await alert.present();

          resolve();
        }
      );
    });

    return promesa;
  }

  searchStatuses(searchText: string) {
    if (this.searchText !== searchText) {
      this.searchText = searchText;
      this.workOrderStatuses = [];
      this.total = null;
      this.page = 0;
    }
    if (!this.canSearch()) {
      // this.businessPartnerSubject.next(this.businessPartners)
      return;
    }
    this.page++;
    return this.getStatuses(this.searchText);
  }

  private getStatuses(searchText: string) {
    const promesa = new Promise((resolve, reject) => {
      const url =
        environment.urlApi + 'WorkOrderSearch/GetWorkOrderStatusTypeByFilter';

      const httpOptions = {
        params: new HttpParams()
          .append('page', this.page.toString())
          .append('take', this.take.toString()),
        headers: new HttpHeaders({
          Accept: 'application/json',
          Authorization: 'Bearer ' + this.authService.getUser().access_token
        })
      };

      const searchObject = {
        // tslint:disable-next-line:object-literal-shorthand
        searchText: searchText
      };

      this.http.post(url, searchObject, httpOptions).subscribe(
        async (res: any) => {
          // console.log(res);
          const resultado = res;

          if (!resultado.data) {
            this.workOrderStatuses = [];
            resolve();
          } else {
            this.workOrderStatuses = [
              ...this.workOrderStatuses,
              ...resultado.data
            ];
            this.total = resultado.total;
            resolve();
          }
        },
        async (error) => {
          this.workOrderStatuses = [];
          console.log('Error en servicio', error);

          const alert = await this.alertCtrl.create({
            header: 'Error',
            subHeader: 'No se pudo cargar la información', // data_resp["api_message"],
            buttons: ['OK']
          });

          await alert.present();

          resolve();
        }
      );
    });

    return promesa;
  }

  searchLockers(searchText: string) {
    if (this.searchText !== searchText) {
      this.searchText = searchText;
      this.lockerPoints = [];
      this.total = null;
      this.page = 0;
    }
    if (!this.canSearch()) {
      // this.businessPartnerSubject.next(this.businessPartners)
      return;
    }
    this.page++;
    return this.getLockers(this.searchText);
  }

  private getLockers(searchText: string) {
    const promesa = new Promise((resolve, reject) => {
      const url =
        environment.urlApi + 'WorkOrderSearch/GetLockerPointByFilters';

      const httpOptions = {
        params: new HttpParams()
          .append('page', this.page.toString())
          .append('take', this.take.toString()),
        headers: new HttpHeaders({
          Accept: 'application/json',
          Authorization: 'Bearer ' + this.authService.getUser().access_token
        })
      };

      const searchObject = {
        // tslint:disable-next-line:object-literal-shorthand
        searchText: searchText
      };

      this.http.post(url, searchObject, httpOptions).subscribe(
        async (res: any) => {
          // console.log(res);
          const resultado = res;

          if (!resultado.data) {
            this.lockerPoints = [];
            resolve();
          } else {
            this.lockerPoints = [...this.lockerPoints, ...resultado.data];
            this.total = resultado.total;
            resolve();
          }
        },
        async (error) => {
          this.lockerPoints = [];
          console.log('Error en servicio', error);

          const alert = await this.alertCtrl.create({
            header: 'Error',
            subHeader: 'No se pudo cargar la información', // data_resp["api_message"],
            buttons: ['OK']
          });

          await alert.present();

          resolve();
        }
      );
    });

    return promesa;
  }
}
