import { OrderDetail } from './order-detail.model';
export class Order {
  // constructor(
  public idWorkOrder: number;
  public idServiceOrder: number;
  public serviceOrderCode: string;
  public idLocker: number;
  public lockerNumber: string;
  public lockerPassword: string;
  public workOrderCode: string;
  public orderNumber: string;
  public order: number;
  public idWorkOrderType: number;
  public workOrderTypeCode: string;
  public workOrderTypeDescription: string;
  public idActivityType: number;
  public idVendor: number;
  public idAssignee: number;
  public totalPrice: number;
  public totalCost: number;
  public isBillable: boolean;
  public isInvoiced: boolean;
  public isAdministrate: boolean;
  public isLocker: boolean;
  public idOrigin: number;
  public originAddress: string;
  public originReference: string;
  public originLatitude: number;
  public originLongitude: number;
  public idDestination: number;
  public destinationAddress: string;
  public destinationReference: string;
  public destinationLatitude: number;
  public destinationLongitude: number;
  public idCurrentWorkOrderStatus: number;
  public currentWorkOrderStatusCode: string;
  public currentWorkOrderStatusDescription: string;
  public currentWorkOrderStatusIcon: string;
  public idWorkOrderGroup: number;
  public modifiedDate: Date;
  public isActive: boolean;
  public icon: string;
  public firstName: string;
  public lastName: string;
  public email: string;
  public phone: string;
  public mobile: string;
  public workOrderDetails: OrderDetail[];
  // ) {}

  public constructor(init?: Partial<Order>) {
    Object.assign(this, init);
  }
}
