import { Injectable } from "@angular/core";
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpParams,
  HttpHeaders,
} from "@angular/common/http";
import { onErrorResumeNext } from "rxjs";
import { exhaustMap, take } from "rxjs/operators";
import { IAuthResponse } from "./models/iauth-response.interface";
import { AuthService } from './services/auth/auth.service';

@Injectable()
export class AuthInterceptorService implements HttpInterceptor {
  constructor(private authService: AuthService) {}

  intercept(req: HttpRequest<any>, handle: HttpHandler) {
    return this.authService.authResponseSubject.pipe(
         take(1),
      exhaustMap((user: IAuthResponse) => {
        if (!user) {
          return handle.handle(req)
        }
        const newReq = req.clone({
          headers: new HttpHeaders({
            Accept: "application/json",
            Authorization: "Bearer " + user.access_token,
          }),
        });
        return handle.handle(newReq);
      })
    );
  }
}
