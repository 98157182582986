import { Component, OnInit, ViewChild } from '@angular/core';
import { IonInfiniteScroll, ModalController } from '@ionic/angular';
import { FiltersService } from '../../services/filters/filters.service';
import { LockerPoint } from '../../models/locker-point.interface';

@Component({
  selector: 'app-locker-point',
  templateUrl: './locker-point.component.html',
  styleUrls: ['./locker-point.component.scss']
})
export class LockerPointComponent implements OnInit {
  @ViewChild(IonInfiniteScroll, null) infiniteScroll: IonInfiniteScroll;
  searchText: string = '';
  isFirstSearching = false;
  isSearching = false;
  lockerPoints: LockerPoint[] = [];
  skeleton = [];

  constructor(
    private modalCtrl: ModalController,
    private filterService: FiltersService
  ) {}

  ngOnInit() {
    this.filterService.lockerPoints = [];
    this.filterService.page = 0;
    this.filterService.total = 0;
    this.isFirstSearching = true;
    this.infiniteScroll.disabled = true;
    this.isSearching = true;
    this.skeleton = this.getSkeletonQuantity();
    this.filterService.searchLockers('').then((result) => {
      this.lockerPoints = this.filterService.lockerPoints;
      this.isFirstSearching = false;
      this.isSearching = false;
      this.infiniteScroll.complete();
      this.infiniteScroll.disabled = !this.filterService.canSearch();
    });
  }

  dismissModal(lockerPointSelected?) {
    // using the injected ModalController this page
    // can "dismiss" itself and optionally pass back data
    this.modalCtrl.dismiss({
      // tslint:disable-next-line:object-literal-shorthand
      lockerPointSelected: lockerPointSelected
    });
  }

  onSearchEvent(event) {
    this.isFirstSearching = true;
    this.infiniteScroll.disabled = true;
    this.lockerPoints = [];
    this.searchText = event.detail.value;
    this.search();
  }

  search() {
    this.isSearching = true;
    this.filterService.searchLockers(this.searchText).then((result) => {
      this.lockerPoints = this.filterService.lockerPoints;
      this.isFirstSearching = false;
      this.isSearching = false;
      this.infiniteScroll.complete();
      this.infiniteScroll.disabled = !this.filterService.canSearch();
    });
  }

  getSkeletonQuantity() {
    let aux: number[] = [];

    for (let i = 0; i < 10; i++) {
      aux.push(i);
    }
    return aux;
  }

  selectRecord(lockerPointSelected) {
    this.dismissModal(lockerPointSelected);
  }
}
